<template>
  <div class="imagegrid">
    <div><router-link to="/game/nimbus"><img src="/img/logo_nimbus.png" /></router-link></div>
    <div><router-link to="/game/snakebird"><img src="/img/logo_snakebird.png" /></router-link></div>
    <div><router-link to="/game/snakebird_primer"><img src="/img/logo_snakebird_primer.png" /></router-link></div>
    <div><router-link to="/game/snakebird_complete"><img src="/img/logo_snakebird_complete.png" /></router-link></div>
    <!--<ul>
      <li><router-link to="/game/nimbus"><img src="/img/logo_nimbus.png" /></router-link></li>
      <li><router-link to="/game/snakebird"><img src="/img/logo_snakebird.png" /></router-link></li>
      <li><router-link to="/game/snakebird_primer"><img src="/img/logo_snakebird_primer.png" /></router-link></li>
    </ul>-->
  </div>
</template>

<script>
export default {
  data() {
      return { nimbus : { game : 'nimbus', video : 'WJJa3hMbs4s', stores : [ { link : 'apa', img : 'test' } ] } };
    }
}
</script>

<style lang="scss" scoped>
  .page
  {
    ul
    {
      padding: 0px;
      li
      {
        margin:40px;
        list-style: none;
      }
    }
  }
</style>
